import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  z-index: 100000;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;

  background-color: rgba(0, 0, 0, 0.85);
  z-index: 10000;
  cursor: pointer;
`;

function EmbedVideo(props) {
  function getSize() {
    const WIDTH_TO_WINDOW_RATIO = 0.65;
    const HEIGHT_TO_WIDTH_RATIO = 0.5265; // 16:9

    return {
      width: window.innerWidth * WIDTH_TO_WINDOW_RATIO,
      height: window.innerWidth * WIDTH_TO_WINDOW_RATIO * HEIGHT_TO_WIDTH_RATIO,
    };
  }

  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => setSize(getSize());

    // Initial video resize as window is not always present before component mounts
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [props.show]);

  return (
    props.show && (
      <Wrapper>
        <Iframe
          style={{ width: size.width, height: size.height }}
          id="embed-video"
          src="https://www.youtube.com/embed/wP1Dg4d5wJc?autoplay=1"
          allow="accelerometer; autoplay; encrypted-media; gyroscope;"
          allowfullscreen
        />
        <Overlay onClick={props.toggle} />
      </Wrapper>
    )
  );
}

export default EmbedVideo;

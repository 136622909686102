import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { v4 as uuid } from 'uuid';

import { forSize } from '../styled-utils';
import NotificationTile from './notificationTile';

const INTERVAL = 5000;
const NOTIFICATIONS_LIMIT = 6;
const variants = [
  {
    heading: '@bartekjacak mentioned you 💬',
    subheading: 'feat: Add dark mode',
    description: '@neonowy bump',
  },
  {
    heading: '@Wowu requested changes ⚠️',
    subheading: 'feat: rewrite application UI',
    description: 'This needs some work',
  },
  {
    heading: 'All checks passed ✅',
    subheading: 'monofocus/MonoFocus: master',
    description: 'fix: tests',
  },
  {
    heading: 'Travis CI - Branch: Failed ❌',
    subheading: 'Add Jira Cloud adapter (#548)',
    description: 'fix: tests',
  },
  {
    heading: '@neonowy reviewed 💬',
    subheading: 'feat: add footer',
    description: 'Just a few nits',
  },
  {
    heading: '@neonowy approved 👍',
    subheading: 'preferences: quick refactor',
    description: 'LGTM :+1:',
  },
  {
    heading: '@neonowy mentioned you 💬',
    subheading: 'feat: add dark mode',
    description: '@wowu maybe now?',
  },
  {
    heading: '@wowu requested changes ⚠️',
    subheading: 'preferences: new icons',
    description: `I'd change the second one`,
  },
  {
    heading: 'All checks passed ✅',
    subheading: 'monofocus/ping-pigeon: master',
    description: 'Fix dock icon behaviour',
  },
  {
    heading: 'Travis CI - Branch: Failed ❌',
    subheading: 'fix: flaky tests',
    description: `¯\\_(ツ)_/¯`,
  },
  {
    heading: '@bartekjacak reviewed 💬',
    subheading: 'feat: add footer',
    description: 'Just a few nits',
  },
  {
    heading: '@wowu approved 👍',
    subheading: 'preferences: refactor',
    description: 'Looks good to me!',
  },
  {
    heading: '@wowu replied to your review 💬',
    subheading: 'feat: add navbar',
    description: `Okay, you're right`,
  },
];

const Wrapper = styled.div`
  position: absolute;
  padding: 20px 24px;
  top: 0;
  right: 0;
  overflow: hidden;
  z-index: 1000;

  ${forSize.phoneOnly`
    width: 95%;
    max-width: 380px;
    margin: auto;
    padding: 20px 12px;
    left: 0;
  `}
`;

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function Notifications() {
  const [items, setItems] = useState([]);
  // Show first notification slightly faster
  const interval = items.length > 0 ? INTERVAL : 300;
  useInterval(appendItem, interval);

  function randomFromArray(array) {
    const randomId = Math.floor(Math.random() * Math.floor(array.length));
    return array[randomId];
  }

  function randomVariant() {
    let variant = randomFromArray(variants);

    // So it is always different than the previous one
    if (items.length > 0) {
      while (variant.heading === items[0].heading) {
        variant = randomFromArray(variants);
      }
    }

    return {
      id: uuid(),
      ...variant,
    };
  }

  function appendItem() {
    if (items.length > NOTIFICATIONS_LIMIT) {
      setItems((items) => [randomVariant(), ...items].slice(0, -1));
    } else {
      setItems((items) => [randomVariant(), ...items]);
    }
  }

  const opacityForNotification = (withIndex) => {
    switch (withIndex) {
      case 0:
      case 1:
      case 2:
        return 1;
      case 3:
        return 0.75;
      case 4:
        return 0.45;
      case 5:
        return 0.2;
      default:
        return 0;
    }
  };

  return (
    <Wrapper>
      <TransitionGroup style={{ display: 'flex', flexDirection: 'column' }}>
        {items.map(({ id, heading, subheading, description }, index) => (
          <CSSTransition key={id} timeout={700} classNames="notification">
            <NotificationTile
              id={`notification-${index}`}
              style={{ opacity: opacityForNotification(index) }}
              heading={heading}
              subheading={subheading}
              description={description}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
    </Wrapper>
  );
}

export default Notifications;

import React from 'react';
import styled from 'styled-components';
import { trackCustomEvent, OutboundLink } from 'gatsby-plugin-google-analytics';

import { Link } from 'gatsby';
import { forSize } from '../styled-utils';
import { Text, SectionWithEmoji } from './base';

const Section = styled(SectionWithEmoji)`
  margin-top: 64px;

  ${forSize.phoneOnly`
    margin-top: 32px;
  `}
`;

const ItemWrapper = styled.div`
  margin: 16px 0 24px 0;

  ${forSize.phoneOnly`
    &:first-of-type {
      margin-top: 32px;
    }
  `}
`;

const Question = styled(Text)`
  font-weight: 500;
`;

const Answer = styled(Text)`
  line-height: 1.5;
`;

const ShowModalLink = styled.span`
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

function FAQ(props) {
  function handleShowModalClick() {
    props.onShowModalClick();
    trackCustomEvent({
      category: 'Button Clicks',
      action: 'Click Show Modal in FAQ',
      label: 'Show Modal in FAQ',
    });
  }

  const questionsWithAnswers = {
    'Why don’t I receive all of my notifications?': (
      <>
        We believe only a few GitHub notifications are useful and relevant, so we’re not showing all
        of them. In case of any issues, feel free to email us at{' '}
        <a href="mailto:hello@monofocus.app">hello@monofocus.app</a>
      </>
    ),
    'Why don’t I see other people repositories?': `Owner of the repository have to install the GitHub app in the repository first.
       You can click on the “Install GitHub app” button and send them the link to the
       installation page. They don’t have to install the app.`,
    'Why don’t I see some organization repositories?': `You need to install GitPigeon app in every organization that you want to receive notifications from.`,
    'Why don’t I receive any notifications?': (
      <>
        Make sure you have allowed notifications from GitPigeon by opening{' '}
        <span style={{ fontStyle: 'italic' }}>System Preferences → Notifications</span>. Restarting
        the app can also help. If the problem persists, email us at{' '}
        <a href="mailto:hello@monofocus.app">hello@monofocus.app</a>
      </>
    ),
    'Do you plan a version for iOS?': (
      <>
        We're working on an iOS app, and you can{' '}
        <OutboundLink
          href="https://forms.gle/Fw1xnR2A77Mkaqwq6"
          target="_blank"
          rel="noopener noreferrer"
        >
          sign up for the waiting list here.
        </OutboundLink>
      </>
    ),
    'How about GitLab support?': (
      <>
        <ShowModalLink onClick={handleShowModalClick}>Sign up here</ShowModalLink> if you are
        interested.
      </>
    ),
    'Will you release a version for Windows or Linux?':
      'We’re not considering supporting other desktop platforms at this moment.',
    'How do you store my data?': (
      <>
        When our servers receive events from your repositories, we send you a notification via
        Apple's servers and don't store any of the data from events. We're using Heroku for running
        servers and the database. Read our <Link to="/privacy">Privacy Policy</Link> for more.
      </>
    ),
  };

  const items = Object.keys(questionsWithAnswers).map((key) => ({
    question: key,
    answer: questionsWithAnswers[key],
  }));

  return (
    <Section emoji="🧐" heading="Frequently Asked Questions" id="faq">
      {items.map((item) => (
        <ItemWrapper key={item.question}>
          <Question big>{item.question}</Question>
          <br />
          <Answer>{item.answer}</Answer>
        </ItemWrapper>
      ))}
    </Section>
  );
}

export default FAQ;

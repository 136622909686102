import React from 'react';
import styled from 'styled-components';
import { forSize } from '../styled-utils';
import { Text, SectionWithEmoji } from './base';

import Tick from '../assets/tick.svg';

const Section = styled(SectionWithEmoji)`
  ${forSize.phoneOnly`
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`;

const Subheading = styled(Text)`
  ${forSize.phoneOnly`
    text-align: center;
  `}
`;

const List = styled.ul`
  margin: 32px 0 0 0;
  padding: 0;
  list-style: none;

  @media (max-width: 380px) {
    margin: 20px 0 0 0;
  }

  ${forSize.phoneOnly`
    display: inline;
  `}
`;

const Item = styled.li`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  position: relative;
  padding-left: 28px;
  margin: 8px -8px 20px 0;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 5px;
    height: 18px;
    width: 18px;
    background: url(${Tick}) 0% 0% no-repeat;
    background-size: contain;
  }

  @media (max-width: 400px) {
    font-size: 16px;
    line-height: 20px;
    padding-left: 24px;

    &:before {
      top: 2px;
      height: 16px;
      width: 16px;
    }
  }
`;

const FeaturesList = (props) => (
  <Section emoji="😰" heading="Tired of all the notifications noise on GitHub?">
    <Subheading>Get notified only when:</Subheading>
    <List>
      <Item>
        You got <span style={{ fontWeight: 500 }}>@mentioned</span> in a comment.
      </Item>
      <Item>Someone reviewed your Pull Request.</Item>
      <Item>Someone requested your review.</Item>
      <Item>Checks on your Pull Request failed.</Item>
      <Item>All checks on your commit passed (CI/CD).</Item>
    </List>
  </Section>
);

export default FeaturesList;

import React from 'react';
import styled from 'styled-components';
import { forSize } from '../styled-utils';

import logo from '../assets/app-icon.png';

const Wrapper = styled.div`
  display: none;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: 64px;
  padding: 8px 0 10px 10px;
  margin: 0 0 12px 0;

  background: #f5f5f5;

  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.13), 0px 0px 1px rgba(0, 0, 0, 0.1),
    inset 0px -1px 0px rgba(0, 0, 0, 0.07), inset 0px 1px 0px rgba(0, 0, 0, 0.07);
  border-radius: 8px;

  ${forSize.tabletPortraitUp`
    width: 380px;
    height: 72px;
    padding: 8px 10px 10px 10px;
  `}

  ${forSize.laptopUp`
    display: flex;
  `}

  &#notification-0, &#notification-1 {
    display: flex !important;
  }
`;

const TextWrapper = styled.div`
  margin-left: 16px;
  color: #4c4c4c;
`;

const Logo = styled.img`
  width: 34px;
  height: 36px;
`;

const Heading = styled.p`
  font-weight: 600;
  font-size: 13px;
  line-height: 1;
  letter-spacing: -0.14;

  margin: 0;
`;

const Subheading = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.07;

  margin: 5px 0 0 0;
`;

const Description = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  letter-spacing: -0.07;

  margin: 5px 0 0 0;
`;

const NotificationTile = ({ heading, subheading, description, ...props }) => (
  <Wrapper className="no-select" {...props}>
    <Logo src={logo} alt="GitPigeon Logo" />
    <TextWrapper>
      <Heading>{heading}</Heading>
      <Subheading>{subheading}</Subheading>
      <Description>{description}</Description>
    </TextWrapper>
  </Wrapper>
);

export default NotificationTile;

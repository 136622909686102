import React, { useState, useEffect, useRef } from 'react';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

import 'src/index.scss';
import Layout from 'src/components/layout';
import Content from 'src/components/content';
import Header from 'src/components/header';
import FeaturesList from 'src/components/featuresList';
import FAQ from 'src/components/faq';

import EmbedVideo from 'src/components/embedVideo';
import Notifications from 'src/components/notifications';
import GitlabModal from 'src/components/gitlabModal';

function IndexPage() {
  const SMALL_SCREEN_SIZE = 679;
  const modalRef = useRef();
  const [isModalShown, setIsModalShown] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const toggleVideo = () => {
    setShowVideo(!showVideo);

    trackCustomEvent({
      category: 'Button Clicks',
      action: 'Click Show Video',
      label: 'Show Video',
    });
  };

  useEffect(() => {
    const htmlNode = document.querySelector('html');
    showVideo ? htmlNode.classList.add('no-scroll') : htmlNode.classList.remove('no-scroll');
  }, [showVideo]);

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth <= SMALL_SCREEN_SIZE);
    // Initial resize
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Layout>
      <Notifications />
      <EmbedVideo show={showVideo} toggle={toggleVideo} />
      <Content>
        <Header
          isSmallScreen={isSmallScreen}
          onShowModalClick={() => {
            setIsModalShown(true);
            setHasModalPopped(true);
          }}
          toggleVideo={toggleVideo}
        />
        <FeaturesList />
        <FAQ onShowModalClick={() => setIsModalShown(true)} />
      </Content>
      <GitlabModal
        isSmallScreen={isSmallScreen}
        modalRef={modalRef}
        isShown={isModalShown}
        setIsShown={setIsModalShown}
      />
    </Layout>
  );
}

export default IndexPage;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { forSize, colors } from '../styled-utils';

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;

  margin: 16px 0 0 0;

  ${forSize.phoneOnly`
    margin: 12px 0 0 0;
  `}
`;

const EmailWrapper = styled.div`
  display: flex;
  align-content: center;

  padding: 0 12px 0 0;

  ${forSize.phoneOnly`
    padding: 0;
  `}
`;

const EmailInput = styled.input`
  flex-grow: 1;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;

  width: 100px;
  padding: 8px 12px;

  color: ${colors.green900};
  background-color: #ffffff;
  border: 1px solid rgba(80, 208, 59, 0.18);
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.01);
  border-radius: 8px;

  ::-webkit-input-placeholder,
  ::-moz-placeholder,
  :-ms-input-placeholder,
  :-moz-placeholder {
    color: rgba(2, 36, 10, 1);
  }

  &:focus {
    outline: none;
    background-color: #ffffff;
  }
`;

const SendButton = styled.input`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  width: 104px;
  padding: 8px 20px;
  margin-left: 8px;

  color: #ffffff;
  background: ${colors.green300};
  border: none;
  border-radius: 8px;
  cursor: pointer;

  transition: 0.2s ease-in;

  &:hover {
    background-color: #50d03b;
    box-shadow: 0 5px 20px 2px rgb(80, 208, 59, 0.25);
  }

  &:focus {
    outline: none;
    background-color: #50d03b;
    box-shadow: 0 5px 20px 2px rgb(80, 208, 59, 0.25);
  }

  ${forSize.phoneOnly`
    width: auto;
  `}
`;

const Note = styled.p`
  display: flex;
  align-content: baseline;

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  margin: 16px 0 0 0;

  color: ${(props) => (props.error ? '#D03B3B' : colors.green900)};

  opacity: ${(props) => (props.error ? 1 : 0.6)};
`;

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

function GitlabForm(props) {
  const HIDE_ERROR_NOTE_DELAY = 5000;
  const [state, setState] = React.useState({});
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const onError = () => setShowError(false);

    if (props.isShown && showError) setTimeout(onError, HIDE_ERROR_NOTE_DELAY);
    return () => clearTimeout(onError);
  }, [showError, props.isShown]);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  function handleSubmit(event) {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'gitlab-list',
        ...state,
      }),
    })
      .then(props.onSuccess)
      .catch(() => setShowError(true));

    event.preventDefault();
  }

  return (
    <FormWrapper
      name="gitlab-list"
      method="post"
      onSubmit={handleSubmit}
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="contact" />
      <p hidden>
        <label>
          Don’t fill this out: <input name="bot-field" onChange={handleChange} />
        </label>
      </p>
      <EmailWrapper>
        <EmailInput
          type="email"
          name="email"
          placeholder="Your email address..."
          onChange={handleChange}
          ref={props.emailRef}
          required
        />
        <SendButton type="submit" value={props.isSent ? 'Done!' : 'Send'} />
      </EmailWrapper>
      {showError ? (
        <Note error>Couldn’t sign up for a waitlist. Try again or refresh the page.</Note>
      ) : (
        <Note>
          We’ll only send you a single email.{!props.isSmallScreen && <>&nbsp;No spam.</>}
        </Note>
      )}
    </FormWrapper>
  );
}

export default GitlabForm;

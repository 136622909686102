import React from 'react';
import styled from 'styled-components';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

import { forSize } from '../styled-utils';
import { Logo, H1, Text, DownloadButton, SecondaryButton, PillWithChevron } from './base';

const Wrapper = styled.header`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 42px 0 48px 0;

  ${forSize.laptopUp`
    padding: 80px 0 64px;
  `}

  ${forSize.phoneOnly`
    padding: 204px 0 64px 0;
    align-items: center;
    text-align: center;
  `}
`;

const AppName = styled(H1)`
  position: relative;
  font-weight: 500;
  margin: 16px 0 0 0;
`;

const Buttons = styled.div`
  display: flex;
  align-content: baseline;

  ${forSize.phoneOnly`
    flex-direction: column;
  `}
`;

const Description = styled(Text)`
  margin: 4px 0 32px 0;

  ${forSize.phoneOnly`
    font-size: 20px;
    margin: 4px 0 20px 0;
  `}
`;

const VideoButton = styled(SecondaryButton)`
  font-weight: 500;
  margin-left: 18px;

  ${forSize.phoneOnly`
    margin-left: 0px;
    margin-top: 12px;
  `}
`;

const PillContainer = styled.div`
  display: flex;
  margin: 32px 0 0 0;
`;

const Bold = styled.span`
  font-weight: 600;
`;

function Header(props) {
  function handleDownloadButtonClick() {
    trackCustomEvent({
      category: 'Button Clicks',
      action: 'Click Download App in Header',
      label: 'Download App',
    });
  }

  function handleShowModalClick() {
    props.onShowModalClick();

    trackCustomEvent({
      category: 'Button Clicks',
      action: 'Click Show Modal in Header',
      label: 'Show Modal in Header',
    });
  }

  return (
    <Wrapper>
      <Logo alt="GitPigeon Logo" />
      <AppName>GitPigeon</AppName>
      <Description big>GitHub&nbsp;notifications&nbsp;delivered to&nbsp;your&nbsp;Mac.</Description>
      <Buttons>
        <DownloadButton onClick={handleDownloadButtonClick} />
        <VideoButton onClick={props.toggleVideo}>Watch the video</VideoButton>
      </Buttons>
      <PillContainer>
        {props.isSmallScreen ? (
          <PillWithChevron
            href="https://forms.gle/Fw1xnR2A77Mkaqwq6"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Bold>Interested in GitPigeon for iOS?</Bold>
          </PillWithChevron>
        ) : (
          <PillWithChevron onClick={handleShowModalClick}>
            <Bold>Using GitLab?</Bold> Sign up for the waitlist
          </PillWithChevron>
        )}
      </PillContainer>
    </Wrapper>
  );
}

export default Header;

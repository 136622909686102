import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

import { forSize, colors } from '../styled-utils';
import { PrimaryButton } from './base';
import GitlabForm from './gitlabForm';
import CrossIcon from 'src/assets/cross.inline.svg';

const Tile = styled.div`
  z-index: 1000;
  opacity: 0;
  pointer-events: none;

  position: fixed;
  bottom: 16px;
  right: 2.5%;

  width: 95%;
  padding: 20px 16px;

  background-color: #f0fff3;
  border-radius: 12px;
  box-shadow: 0px 8px 20px -6px rgba(0, 0, 0, 0.06);

  ${forSize.tabletPortraitUp`
    bottom: 32px;
    right: 36px;

    padding: 20px 24px;
    width: 420px;
    transform: scale(0.85);
  `}

  &.shown {
    opacity: 1;
    transform: scale(1);
    pointer-events: all;
    transition: transform 0.3s cubic-bezier(0.25, 0.75, 0.5, 1.25),
      opacity 0.15s cubic-bezier(0.25, 0.75, 0.5, 1.25);
  }
`;

const Heading = styled.h2`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  padding: 0 24px 0 0;
  margin: 0 0 8px 0;

  color: ${colors.green900};

  ${forSize.phoneOnly`
    font-weight: 500;
  `}
`;

const Bold = styled.span`
  font-weight: 600;
`;

const Cross = styled(CrossIcon)`
  position: absolute;
  top: 24px;
  right: 20px;

  width: 18px;
  height: 18px;

  cursor: pointer;

  path {
    fill: #8ee8a0;
    transition: 0.3s ease-in;
  }

  &:hover {
    path {
      fill: #5abf6e;
    }
  }

  ${forSize.phoneOnly`
    top: 22px;
    right: 18px;
  `}
`;

const ExpandButton = styled(PrimaryButton)`
  font-size: 16px;

  position: fixed;
  right: 24px;
  bottom: 16px;

  padding: 8px 16px;

  z-index: 100;
`;

function GitlabModal(props) {
  const HIDE_AFTER_SEND_DELAY = 2000;
  const emailInput = useRef();
  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    if (props.isShown) emailInput.current.focus();
  }, [props.isShown]);

  useEffect(() => {
    function onSend() {
      emailInput.current.value = '';
      props.setIsShown(false);
      setIsSent(false);
    }

    if (props.isShown && isSent) setTimeout(onSend, HIDE_AFTER_SEND_DELAY);
    return () => clearTimeout(onSend);
  }, [isSent, props]);

  return (
    <>
      <Tile ref={props.modalRef} className={props.isShown && 'shown'}>
        <Cross onClick={() => props.setIsShown(false)} />
        <Heading>
          {!props.isSmallScreen && <Bold>Using&nbsp;GitLab?&nbsp;</Bold>}Get&nbsp;email when we
          release GitPigeon for GitLab.
        </Heading>
        <GitlabForm emailRef={emailInput} onSuccess={() => setIsSent(true)} isSent={isSent} />
      </Tile>
      {props.isSmallScreen && (
        <ExpandButton
          onClick={() => {
            props.setIsShown(true);
            trackCustomEvent({
              category: 'Button Clicks',
              action: 'Click Show GitLab Modal on Mobile',
              label: 'Show GitLab Modal on Mobile',
            });
          }}
        >
          Using GitLab?
        </ExpandButton>
      )}
    </>
  );
}

export default GitlabModal;
